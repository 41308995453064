.footer {
  display: flex;
  flex-direction: column;
  z-index: 8;
}
.footer .menuWrap {
  background-color: #ebfeeb;
  padding: 45px 20px;
}
.footer .menuWrap .largeScreenResponsive {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
}
.footer .menuWrap .largeScreenResponsive .logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
}
.footer .menuWrap .largeScreenResponsive .logo .logoLink {
  display: flex;
  width: 250px;
  height: 105px;
  flex-direction: row;
  align-items: center;
}
.footer .menuWrap .largeScreenResponsive .logo .paragraph {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  color: #989898;
  font-weight: 400;
  line-height: 150%;
  margin-left: 15px;
}
.footer .menuWrap .largeScreenResponsive .menu {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.footer .menuWrap .largeScreenResponsive .menu .header {
  font-size: 20px;
  margin: 0.83em 0;
  color: black;
  font-weight: 600;
  line-height: 180%;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 0;
  margin: 10px 0;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu li {
  list-style: none;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu li .active {
  color: #f97206;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu li:nth-child(1) {
  order: 0;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu li:nth-child(2) {
  order: 2;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu li:nth-child(3) {
  order: 4;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu li:nth-child(4) {
  order: 1;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu li:nth-child(5) {
  order: 3;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu li:nth-child(6) {
  order: 6;
}
@media (min-width: 640px) {
  .footer .menuWrap .largeScreenResponsive .menu .listMenu {
    gap: 40px;
    grid-template-columns: repeat(6, 1fr);
  }
  .footer .menuWrap .largeScreenResponsive .menu .listMenu li:nth-child(5) {
    margin-left: -10px;
  }
  .footer .menuWrap .largeScreenResponsive .menu .listMenu li {
    order: unset !important;
  }
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu a {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu a {
  transition: all 0.2s ease;
}
.footer .menuWrap .largeScreenResponsive .menu .listMenu a:hover {
  transform: translateY(-2px);
  color: #f97206;
}
@media (min-width: 700px) {
  .footer .menuWrap .largeScreenResponsive {
    padding: 45px 80px;
  }
}
@media (min-width: 1088px) {
  .footer .menuWrap .largeScreenResponsive {
    flex-direction: row;
    justify-content: space-between;
    padding: 45px 150px;
    align-items: center;
  }
  .footer .menuWrap .largeScreenResponsive .logo .paragraph {
    font-size: 16px;
    width: 80%;
  }
  .footer .menuWrap .largeScreenResponsive .menu {
    padding-left: 40px;
  }
  .footer .menuWrap .largeScreenResponsive .menu .listMenu a {
    font-size: 18px !important;
  }
}
.footer .copyright {
  background-color: #389d64;
  padding: 10px 25px;
}
.footer .copyright .largeScreenResponsive {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
}
.footer .copyright .largeScreenResponsive .copyrightText {
  font-size: 16px;
}
@media (min-width: 700px) {
  .footer .copyright .largeScreenResponsive.largeScreenResponsive {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 100px;
  }
  .footer
    .copyright
    .largeScreenResponsive.largeScreenResponsive
    .copyrightText {
    font-size: 18px !important;
  }
  .footer .copyright .largeScreenResponsive.largeScreenResponsive .socials {
    order: 0;
    padding: 0 !important;
  }
}
@media (min-width: 900px) {
  .footer .copyright .largeScreenResponsive.largeScreenResponsive {
    padding: 0 150px;
  }
}
.footer .copyright .largeScreenResponsive .copyrightText {
  color: white;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin: 21px 0;
}
.footer .copyright .largeScreenResponsive .socials {
  display: none !important;
  display: flex;
  justify-content: center;
  order: -1;
  padding: 20px;
}
.footer .copyright .largeScreenResponsive .socials .linkSocials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}
.footer
  .copyright
  .largeScreenResponsive
  .socials
  .linkSocials:not(:first-child) {
  margin-left: 140px;
}
.footer .copyright .largeScreenResponsive .socials .linkSocials:hover {
  transform: translateY(-3px);
  background-color: #f4d03f;
}
