body {
  font-family: "Plus-Jakarta", sans-serif;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;

    transform: translateY(0);
  }
}

/* localfont */
@font-face {
  font-family: "Plus-Jakarta";
  src: url("../public/assets/fonts/PlusJakartaSans-Light.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Plus-Jakarta";
  src: url("../public/assets/fonts/PlusJakartaSans-Medium.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Plus-Jakarta";
  src: url("../public/assets/fonts/PlusJakartaSans-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Plus-Jakarta";
  src: url("../public/assets/fonts/PlusJakartaSans-Bold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Plus-Jakarta";
  src: url("../public/assets/fonts/PlusJakartaSans-ExtraBold.ttf");
  font-weight: 700;
}
